


let homeTitleImg = document.getElementById('home-title-img');

setTimeout(
	function () {
		if (typeof (homeTitleImg) != 'undefined' && homeTitleImg != null) {
			homeTitleImg.classList.add('animated');
		}
	},
	300
);


function isInViewport(el) {
	const rect = el.getBoundingClientRect();
	return (
		rect.top <= ((window.innerHeight || document.documentElement.clientHeight) - 300)
	);
}

const animateElements = document.querySelectorAll('.animate');

document.addEventListener('scroll', function () {
	Array.from(animateElements).forEach(function (animateElement) {
		if (isInViewport(animateElement)) {
			if (animateElement.hasAttribute('data-animateDelay')) {
				let animateDelay = animateElement.getAttribute('data-animateDelay');
				setTimeout(
					function () {
						animateElement.classList.add('animated');
					},
					animateDelay
				);
			}
			else {
				animateElement.classList.add('animated');
			}

		}
	});
});

let headerHeight = 90;
if (window.innerWidth <= 768) {
	headerHeight = 60;
}

const smoothAnchors = document.querySelectorAll('.smooth-anchor');
for (const smoothAnchor of smoothAnchors) {
	smoothAnchor.addEventListener("click", clickHandler);
}
function clickHandler(e) {
	e.preventDefault();
	const href = this.getAttribute("href");
	const offsetTop = document.querySelector(href).offsetTop - headerHeight;

	scroll({
		top: offsetTop,
		behavior: "smooth"
	});
}



/* Slide up */
function slideUp(target, duration) {
	target.style.transitionProperty = 'height, margin, padding';
	target.style.transitionDuration = duration + 'ms';
	target.style.boxSizing = 'border-box';
	target.style.height = target.offsetHeight + 'px';
	target.offsetHeight;
	target.style.overflow = 'hidden';
	target.style.height = 0;
	target.style.paddingTop = 0;
	target.style.paddingBottom = 0;
	target.style.marginTop = 0;
	target.style.marginBottom = 0;
	window.setTimeout(function () {
		target.style.display = 'none';
		target.style.removeProperty('height');
		target.style.removeProperty('padding-top');
		target.style.removeProperty('padding-bottom');
		target.style.removeProperty('margin-top');
		target.style.removeProperty('margin-bottom');
		target.style.removeProperty('overflow');
		target.style.removeProperty('transition-duration');
		target.style.removeProperty('transition-property');
	}, duration);
}

/* Slide down */
function slideDown(target, duration) {
	target.style.removeProperty('display');
	var display = window.getComputedStyle(target).display;
	if (display === 'none') display = 'block';
	target.style.display = display;
	var height = target.offsetHeight;
	target.style.overflow = 'hidden';
	target.style.height = 0;
	target.style.paddingTop = 0;
	target.style.paddingBottom = 0;
	target.style.marginTop = 0;
	target.style.marginBottom = 0;
	target.offsetHeight;
	target.style.boxSizing = 'border-box';
	target.style.transitionProperty = "height, margin, padding";
	target.style.transitionDuration = duration + 'ms';
	target.style.height = height + 'px';
	target.style.removeProperty('padding-top');
	target.style.removeProperty('padding-bottom');
	target.style.removeProperty('margin-top');
	target.style.removeProperty('margin-bottom');
	window.setTimeout(function () {
		target.style.removeProperty('height');
		target.style.removeProperty('overflow');
		target.style.removeProperty('transition-duration');
		target.style.removeProperty('transition-property');
	}, duration);
}

/* slide toggle */
function slideToggle(target, duration) {
	if (window.getComputedStyle(target).display === 'none') {
		return slideDown(target, duration);
	} else {
		return slideUp(target, duration);
	}
}


/* Accordion */
const accordionTitles = document.querySelectorAll('.js-accordion-title');
Array.from(accordionTitles).forEach(function (accordionTitle) {
	accordionTitle.onclick = function () {
		if (!this.classList.contains('active')) {
			let theContent = this.closest('.js-accordion-line').querySelector('.js-accordion-content');
			slideDown(theContent, 250);
			this.classList.add('active');
		}
		else {
			let theContent = this.closest('.js-accordion-line').querySelector('.js-accordion-content');
			slideUp(theContent, 250);
			this.classList.remove('active');
		}
	}
});

/* Lock / release scroll */
function lockScroll() {
	let scrollTop = document.querySelector('html').scrollTop ? document.querySelector('html').scrollTop : document.querySelector('body').scrollTop;
	document.querySelector('html').classList.add('noscroll');
	document.querySelector('html').style.top = -scrollTop + 'px';
}
function releaseScroll() {
	let scrollTop = parseInt(document.querySelector('html').style.top);
	document.querySelector('html').classList.remove('noscroll');
	document.querySelector('html,body').scrollTop = -scrollTop;
}

/* POPIN */
//open
const popinOpenButtons = document.querySelectorAll('.js-open-popin');
Array.from(popinOpenButtons).forEach(function (popinOpenButton) {
	popinOpenButton.onclick = function () {
		let popinId = popinOpenButton.dataset.popin;
		let popin = document.getElementById(popinId);
		popin.classList.add('active');
		lockScroll()
	}
});

//close
const popinCloseButtons = document.querySelectorAll('.js-popin-close');
Array.from(popinCloseButtons).forEach(function (popinCloseButton) {
	popinCloseButton.onclick = function (e) {
		e.stopPropagation();
		releaseScroll()
		popinCloseButton.closest('.js-popin').classList.remove('active');
	}
});



$('.js-expand-title').on('click', function(){
	let content = $(this).parent().find('.js-expand-content');
	if( $(this).hasClass('active') ){
		content.slideUp(200);
		$(this).removeClass('active');
	}
	else{
		content.slideDown(200);
		$(this).addClass('active');
	}
});

$('.js-expand-title-sub').on('click', function () {
	let content = $(this).parent().find('.js-expand-content-sub');
	if ($(this).hasClass('active')) {
		content.slideUp(200);
		$(this).removeClass('active');
	}
	else {
		content.slideDown(200);
		$(this).addClass('active');
	}
});


let buttonStripe = $('.stripe-button-el');
if (typeof (buttonStripe) != 'undefined' && buttonStripe != null) {
	buttonStripe.find('span').html('Acheter');
}


/* MENU */

$('.menu-burger').on('click', function(){

	if ( $('.menu__content').hasClass('open') ){
		$('.menu__content').removeClass('open');
		$('.menu-burger').removeClass('active');
	}
	else{
		$('.menu__content').addClass('open');
		$('.menu-burger').addClass('active');
	}
})

/* ASIDE BUTTON */
$('.aside__button').on('click', function () {
	if( $(this).hasClass('open') ){
		$(this).removeClass('open');
		$('.template-formation__aside').removeClass('open');
	}
	else{
		$(this).addClass('open');
		$('.template-formation__aside').addClass('open');
	}
	
});

/* QUIZ */
/*
let quizItem = 1;
$('.quiz-prev').on('click', function () {
	if (quizItem > 1) {
		quizItem = (quizItem - 1);
		showQuizItem(quizItem);
	}
});

$('.quiz-next').on('click', function () {
	if ( quizItem < 6 ){
		quizItem = (quizItem + 1);
		showQuizItem(quizItem);
	}
});

$('.quiz-bullet').on('click', function(){
	quizItem = $(this).data('item');
	showQuizItem(quizItem);
});

function showQuizItem(itemNumber){
	$('.quiz-prev').removeClass('hidden');
	$('.quiz-next').removeClass('hidden');
	$('.quiz__item').hide(0);
	$('#quiz__item-'+itemNumber).show(0);
	$('.quiz-bullet').removeClass('active');
	$('#bullet-' + itemNumber).addClass('active');
	if( itemNumber == 1){
		$('.quiz-prev').addClass('hidden');
	}
	if (itemNumber == 6) {
		$('.quiz-next').addClass('hidden');
	}

}
*/

if ($('.swiper').length) {
	const swiper = new Swiper('.swiper', {
		// If we need pagination
		pagination: {
			el: '.swiper-pagination',
			clickable: true,
		},

		// Navigation arrows
		navigation: {
			nextEl: '.button-next',
			prevEl: '.button-prev',
		},
	});
}



if ($('input[name=swpm-login]').length ){
	$('input[name=swpm-login]').val('Se connecter');
}
if ($('.swpm-username-label').length ){
	$('.swpm-username-label .swpm-label').html("Nom d'utilisateur ou email");
} 
if ($('.aside__sommaire').length) {
	$('.aside__sommaire').animate({
		scrollTop: ( $('.current-menu-item').offset().top - 250)
	}, 0);
} 

if ($('.button-pay-formation').length){
	$('.button-pay-formation').find('.stripe-button-el').html('Commencer la formation');
	$('.button-pay-formation').find('.stripe-button-el').addClass('stripe-button-el--l');
	$('.button-pay-formation').find('.stripe-button-el').addClass('stripe-button-el--full');
}

if ($('.button-pay-formation-mobile').length) {
	$('.button-pay-formation-mobile').find('.stripe-button-el').html('Commencer');
}

if ($('.home-button-pay-formation').length) {
	$('.home-button-pay-formation').find('.stripe-button-el').html('Commencer la formation <i class="fas fa-arrow-circle-right"></i>');
	$('.home-button-pay-formation').find('.stripe-button-el').addClass('stripe-button-el--xl');
}
if ($('.home-button-pay-formation-2').length) {
	$('.home-button-pay-formation-2').find('.stripe-button-el').html('Commencer la formation <i class="fas fa-arrow-circle-right"></i>');
	$('.home-button-pay-formation-2').find('.stripe-button-el').addClass('stripe-button-el--l');
}

function checkPasswordMatch() {
	var password = $("#password").val();
	var confirmPassword = $("#password_confirmation").val();

	if (password != confirmPassword)
		return false;
	else
		return true;
}

function checkRequiredFields() {
	var password = $("#password").val();
	var confirmPassword = $("#password_confirmation").val();
	var firstname = $("#firstname").val();

	if (password == "" || confirmPassword == "" || firstname == "") {
		return false;
	}
	else {
		return true; 
	}
}

function checkboxChecked() {
	var checkbox = document.getElementById("terms");
	if (checkbox.checked == false) {
		return false;
	}
	else {
		return true;
	}
}
if ($("#submitbtn").length ){
	var buttonSubmit = $("#submitbtn");
	buttonSubmit.onclick = function (e) {
		e.preventDefault();
		if (!checkRequiredFields()) {
			alert("Veuillez remplir tous les champs");
		}
		else if (!checkPasswordMatch()) {
			alert("Les mots de passe ne correspondent pas");
		}
		else if (!checkboxChecked()) {
			alert("Veuillez accepter les conditions générales d'utilisation");
		}
		else {
			var form = document.getElementById("wp_signup_form");
			form.submit();
		}
	};
}

if ($('.wpcf7-form').length) {
	document.addEventListener('DOMContentLoaded', function () {
		// Sélectionnez le formulaire de contact par son ID
		var contactForm = document.querySelector('.wpcf7-form');

		// Ajoutez un écouteur d'événement sur la soumission du formulaire
		contactForm.addEventListener('submit', function (event) {
			// Empêcher la soumission du formulaire par défaut
			event.preventDefault();

			// Sélectionnez le bouton de soumission du formulaire
			var submitButton = contactForm.querySelector('[type="submit"]');

			// Désactivez le bouton de soumission du formulaire
			submitButton.disabled = true;

			// Vérifiez que tous les champs obligatoires sont remplis
			var requiredFields = contactForm.querySelectorAll('.wpcf7-validates-as-required');
			var formIsValid = true;
			for (var i = 0; i < requiredFields.length; i++) {
				if (!requiredFields[i].value) {
					formIsValid = false;
					break;
				}
			}

			if (formIsValid) {
				// Envoyez le formulaire via l'API de Contact Form 7
				var data = new FormData(contactForm);
				fetch(contactForm.getAttribute('action'), {
					method: 'POST',
					body: data
				})
					.then(function (response) {
						// Réactivez le bouton de soumission du formulaire
						submitButton.disabled = false;

						// Vérifiez la réponse de Contact Form 7
						if (response.ok) {
							// Le message a été envoyé avec succès
							$('.contact-success').fadeIn(200);
							$('.contact-success').delay(5000).fadeOut(200);
							contactForm.reset();
						} else {
							// Il y a eu une erreur lors de l'envoi du message
							$('.contact-error').fadeIn(200);
							$('.contact-error').delay(5000).fadeOut(200);
						}
					})
					.catch(function (error) {
						// Réactivez le bouton de soumission du formulaire
						submitButton.disabled = false;

						// Affichez une erreur
						console.log(error);
					});
			} else {
				// Réactivez le bouton de soumission du formulaire
				submitButton.disabled = false;

				// Affichez une erreur
				$('.contact-error-input').fadeIn(200);
				$('.contact-error-input').delay(5000).fadeOut(200);
			}
		});
	});
}
